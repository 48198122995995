html {
  background-image: url("./assets/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  transition: 0.5s font-size;
  font-size: 135% !important;

  @media screen and (max-width: 1700px) and (min-width: 1501px) {
    font-size: 62.5% !important;
  }

  @media screen and (max-width: 1500px) and (min-width: 1301px) {
    font-size: 62.5% !important;
  }

  @media screen and (max-width: 1300px) {
    font-size: 62.5% !important;
  }
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.player {
  width: 100%;
  height: 100%;
}

div {
  &::-webkit-scrollbar {
    direction:ltr;

    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: inherit;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    transform: skew(-50deg) rotate(-50deg);
    background-color: white;
  }

  @supports (-moz-appearance: none) {
    scrollbar-width: thin;
    scrollbar-color: white transparent;
  }
}